<script lang="ts" setup>
import { Timestamp } from "firebase/firestore";
import type { SubmittedDocument } from "types/SubmittedDocument";
import { DocumentState } from "types/enums/DocumentState";
import { DocumentViewMode } from "types/enums/DocumentViewMode.enum";
import { DocumentSubmissionTypeLabels } from "~/types/enums/DocumentSubmissionType.enum";

const props = defineProps({
  submittedDocument: {
    type: Object as PropType<SubmittedDocument>,
    required: true,
  },
  // Mode is either teacher or student
  // Limit the value to one of those two
  mode: {
    type: String as PropType<DocumentViewMode>,
    default: DocumentViewMode.teacher,
  },
});

const isDeleting = ref(false);
const onDelete = async (ev: MouseEvent) => {
  ev.preventDefault();
  ev.stopPropagation();
  const response = await confirm(
    "Are you sure you want to delete this document?"
  );

  if (!response) return;

  const { deleteDocument } = useDocuments();

  if (!props.submittedDocument.id) return;

  isDeleting.value = true;
  await deleteDocument(props.submittedDocument.id);
  isDeleting.value = false;
};

const isDocumentReady = computed(() => {
  return useIsDocumentReadyToGrade(props.submittedDocument);
});

const documentPath = computed(() => {
  return useGenerateDocumentPath(props.submittedDocument, props.mode);
});

const capabilitiesService = useCapabilitiesServiceStore();
const { userCapabilities } = storeToRefs(capabilitiesService);

const cannotGradeDocument = computed(() => {
  return (
    props.mode == DocumentViewMode.teacher &&
    useCanGradeDocument(props.submittedDocument, props.mode) != true
  );
});

const showSubscriptionMessage = computed(() => {
  return (
    cannotGradeDocument.value == true && showPurchasePacksMessage.value != true
  );
});

const showPurchasePacksMessage = computed(() => {
  return (
    cannotGradeDocument.value == true &&
    userCapabilities.value?.hasSubscription == true
  );
});

const nameId = computed(() => {
  return props.submittedDocument.name.toLowerCase().split(" ").join("-");
});
const documentInfo = computed(() => {
  const info = [] as string[];

  if (props.submittedDocument.studentName) {
    info.push(props.submittedDocument.studentName);
  }

  if (props.submittedDocument.assignmentName) {
    info.push(props.submittedDocument.assignmentName);
  }

  return info;
});

const documentState = computed(() => {
  if (props.mode == DocumentViewMode.teacher)
    return props.submittedDocument.state;

  // Here I need to map states

  switch (props.submittedDocument.state) {
    case DocumentState.graded:
    case DocumentState.uploading:
    case DocumentState.evaluating:
    case DocumentState.converting:
    case DocumentState.pending:
    case DocumentState.studentReview:
      return props.submittedDocument.state;
    default:
      return DocumentState.submitted;
  }
});

const canShowDeleteButton = computed(() => {
  if (props.mode == DocumentViewMode.teacher) return true;

  // These states do not allow the student to archive the document
  return (
    [
      DocumentState.converting,
      DocumentState.evaluating,
      DocumentState.graded,
      DocumentState.grading,
    ].includes(props.submittedDocument.state) != true
  );
});

const canShowIgnoreButton = computed(() => {
  if (props.mode == DocumentViewMode.student) return false;

  return props.submittedDocument.state == DocumentState.googlePermissionError;
});

const ignoreDocument = async () => {
  props.submittedDocument.state = DocumentState.ignored;
  await useDocuments().updateDocument(props.submittedDocument);
};

const interval = ref<NodeJS.Timer | undefined>();

onMounted(() => {
  if (props.submittedDocument.state == DocumentState.converting) {
    clearSavedInterval();

    interval.value = setInterval(checkForConversionError, 1000);
  } else {
    clearSavedInterval();
  }
});

const checkForConversionError = () => {
  const timeSinceLastUpdated =
    Date.now() - props.submittedDocument.lastUpdatedTimestamp;

  const isConversionTimeout =
    props.submittedDocument.state === DocumentState.converting &&
    timeSinceLastUpdated > 60000;

  if (isConversionTimeout) {
    clearSavedInterval();

    props.submittedDocument.state = DocumentState.conversionFailed;
    useDocuments().updateDocument(props.submittedDocument);

    useReportProblem().reportProblem(
      {
        description: `Document conversion failed on ${useBasicDateTimeFormatter(
          Date.now()
        )}: ${props.submittedDocument.id}`,
        urgency: ReportedProblemUrgency.low,
        userId: useCurrentUID()!,
        userEmail: useCurrentUserEmail()!,
        createdAtTimestamp: Timestamp.now(),
        url: window.location.href,
      },
      []
    );
  }
};

const clearSavedInterval = () => {
  if (interval.value) {
    clearInterval(interval.value as any);
  }
};

onUnmounted(() => {
  clearSavedInterval();
});

const appUserData = useAppUserData();
const { isPartOfAnyOrganization, hasLMSConfig } = storeToRefs(appUserData);

const canEditDocument = computed(() => {
  return hasLMSConfig.value == true;
});

const onEditDocument = () => {
  useOpenDocumentEditModal(props.submittedDocument);
};

const onViewGoogleDriveFile = () => {
  window.open(
    `https://drive.google.com/file/d/${props.submittedDocument.googleDriveFileId}/view`
  );
};
</script>

<template>
  <NuxtLink :to="documentPath" target="_blank">
    <div
      class="flex flex-col bg-surface p-4 border-b last:border-none justify-center relative"
      :class="{
        'cursor-pointer hover:bg-light-default': isDocumentReady,
      }"
      :data-step-id="nameId"
    >
      <div
        v-if="submittedDocument.googleDriveFileId"
        class="absolute top-0 left-0 triangle"
        @click.stop.prevent="onViewGoogleDriveFile"
      >
        <icon
          v-tooltip="'Open in Google Drive'"
          name="mingcute:drive-fill"
          class="text-google-on ml-[2px] mt-[-2px]"
          size="20"
        />
      </div>
      <span class="flex flex-row items-center justify-between w-full">
        <div class="flex flex-row items-center grow pr-4 overflow-hidden">
          <div
            v-if="
              documentPath &&
              isDocumentReady &&
              useCanGradeDocument(submittedDocument, props.mode)
            "
            class="flex flex-row items-center"
          >
            <BaseTextButton
              v-if="canEditDocument"
              @click.stop.prevent="onEditDocument"
            >
              Edit
            </BaseTextButton>
            <NuxtLink :to="documentPath" target="_blank" class="mr-4">
              <BaseTextButton data-step-id="documents-list-item-view">
                View
              </BaseTextButton>
            </NuxtLink>
          </div>
          <div class="flex flex-col w-full overflow-hidden text-ellipsis">
            <span
              class="text-h6 font-bold w-full whitespace-nowrap overflow-hidden truncate text-ellipsis flex flex-row items-center"
            >
              <span
                v-if="submittedDocument.documentSubmissionType"
                class="text-warning-default"
              >
                {{
                  DocumentSubmissionTypeLabels[
                    submittedDocument.documentSubmissionType
                  ].shortLabel
                }}
              </span>
              <span
                v-if="submittedDocument.documentSubmissionType"
                class="mx-1"
              >
                |
              </span>
              <span class="overflow-hidden text-ellipsis grow">
                <span>
                  {{ submittedDocument.name }}
                </span>
              </span>
            </span>

            <div class="flex flex-row items-center">
              <span
                v-for="(info, index) in documentInfo"
                :key="index"
                class="border-r last:border-none mr-2 pr-2 last-pr-0 last:mr-0"
              >
                {{ info }}
              </span>
            </div>
            <span v-if="submittedDocument.submittedAtTimestamp">
              Submitted:
              {{
                useBasicDateTimeFormatter(
                  submittedDocument.submittedAtTimestamp.toMillis
                    ? submittedDocument.submittedAtTimestamp.toMillis()
                    : (submittedDocument.submittedAtTimestamp as any)
                )
              }}
            </span>
          </div>
        </div>
        <div class="flex flex-row items-center shrink-0 justify-end">
          <span class="flex flex-row items-center justify-between">
            <span class="flex flex-row items-center">
              <span
                v-if="
                  submittedDocument.state == DocumentState.uploading ||
                  submittedDocument.state == DocumentState.converting ||
                  submittedDocument.state == DocumentState.evaluating
                "
                class="mr-2"
              >
                <icon name="svg-spinners:ring-resize" size="24" />
              </span>

              <span
                v-if="submittedDocument.isLate == true"
                class="mr-4 text-danger-default"
              >
                late
              </span>
            </span>
            <div class="flex flex-col items-center">
              <DocumentStatePill :key="documentState" :state="documentState" />
              <BaseTextButton
                v-if="canShowIgnoreButton"
                class="mt-2"
                @click="ignoreDocument"
              >
                Ignore
              </BaseTextButton>
              <DocumentGradedStatePill
                v-if="
                  submittedDocument.documentGradedState != undefined &&
                  useShowDocumentGradedState(submittedDocument) == true
                "
                :document-graded-state="submittedDocument.documentGradedState"
                class="mt-2"
              />
              <span
                v-if="
                  submittedDocument.state == DocumentState.graded &&
                  useShowDocumentGradedState(submittedDocument) != true &&
                  submittedDocument.earnedPoints != undefined &&
                  submittedDocument.maxPoints != undefined
                "
                class="mt-2 p-2 border-2 bg-light-default rounded shrink-0 w-full text-center"
              >
                {{ submittedDocument.earnedPoints }} /
                {{ submittedDocument.maxPoints }} points
              </span>
            </div>
          </span>
          <div class="ml-2 flex flex-row shrink-0">
            <BaseTextButton
              v-if="canShowDeleteButton"
              :show-spinner="isDeleting"
              color="danger"
              @click="onDelete"
            >
              Delete
            </BaseTextButton>
          </div>
        </div>
      </span>
      <div
        v-if="submittedDocument.state == DocumentState.conversionFailed"
        class="text-center border border-dashed border-danger-default p-2 mt-2 bg-danger-default bg-opacity-10"
      >
        An error occurred. Delete this submission and try again.
      </div>
      <div
        v-if="showSubscriptionMessage"
        class="bg-primary-default bg-opacity-10 border border-primary-default border-dashed mt-2 p-2 text-center flex flex-col"
      >
        <span v-if="isPartOfAnyOrganization != true">
          You need to subscribe to IEW Gradebook to grade your own submissions.
        </span>
        <span v-if="isPartOfAnyOrganization">
          You do not have an active IEW Gradebook license. Ask your organization
          administrator to provide a license.
        </span>
        <NuxtLink
          v-if="isPartOfAnyOrganization != true"
          to="/order/cart/create"
          class="mt-1"
        >
          <BaseTextButton> Subscribe </BaseTextButton>
        </NuxtLink>
      </div>
      <div
        v-if="showPurchasePacksMessage"
        class="bg-primary-default bg-opacity-10 border border-primary-default border-dashed mt-2 p-2 text-center flex flex-col"
      >
        <span v-if="isPartOfAnyOrganization != true">
          You have run out of submissions for the month. Please upgrade your
          plan or purchase extra submissions to grade this document.
        </span>
        <span v-if="isPartOfAnyOrganization">
          You have run out of submissions for the month. Please ask your
          organization administrator to upgrade your plan or purchase a
          submission pack to grade this document.
        </span>
        <NuxtLink
          v-if="isPartOfAnyOrganization != true"
          to="/configure/subscription"
          class="mt-1"
        >
          <BaseTextButton> Change Subscription </BaseTextButton>
        </NuxtLink>
      </div>
    </div>
  </NuxtLink>
</template>

<style scoped>
/* HTML: <div class="triangle"></div> */
.triangle {
  width: 43px;
  aspect-ratio: 1;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  @apply bg-google-default;
}
</style>
